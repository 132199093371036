<template>
  <div class="content-wrapper">
    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div
            data-v-5cb60a6f=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cb60a6f=""
              class="m-2"
            >
              <div
                data-v-5cb60a6f=""
                class="row"
              >
                <div class="col-3">
                  <router-link
                    class="btn btn-primary"
                    :to="{ name: 'apps-region-add'}"
                  >
                    Add
                  </router-link>
                </div>
              </div>
            </div>
            <div
              data-v-5cb60a6f=""
              class="position-relative table-responsive"
            >
              <table
                id="__BVID__1070"
                role="table"
                aria-busy="false"
                aria-colcount="7"
                class="table b-table"
              ><!---->
                <!---->
                <thead
                  role="rowgroup"
                  class=""
                ><!---->
                  <tr
                    role="row"
                    class=""
                  >
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="1"
                      aria-sort="descending"
                      class=""
                    >
                      <div>#</div>
                    </th><th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="3"
                      aria-sort="none"
                      class=""
                    >
                      <div>Name</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                    >
                      <div>Created at</div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody role="rowgroup"><!---->
                  <tr
                    v-for="(item,index) in getterListRegion"
                    id="__BVID__1070__row_5036"
                    role="row"
                    data-pk="5036"
                    class=""
                  >
                    <td
                      aria-colindex="1"
                      role="cell"
                      class=""
                    ><a
                      data-v-5cb60a6f=""
                      href="/apps/invoice/preview/5036"
                      class="font-weight-bold"
                      target="_self"
                    > {{ index + 1 }} </a></td>
                    <td
                      aria-colindex="3"
                      role="cell"
                      class=""
                    >
                      <div
                        data-v-5cb60a6f=""
                        class="media"
                      >
                        <div
                          data-v-5cb60a6f=""
                          class="media-body"
                        ><span
                          data-v-5cb60a6f=""
                          class="font-weight-bold d-block text-nowrap"
                        > {{ item.name }} </span></div>
                      </div>
                    </td>
                    <td
                      aria-colindex="6"
                      role="cell"
                      class=""
                    >
                      {{ getDate(new Date(item.created_at )) }}
                    </td>
                    <td>
                      <button class="btn btn-danger" @click="handleDeleteRegion(item.id)">DELETE</button>
                    </td>
                  </tr>
                </tbody><!----></table>
            </div>
            <div
              data-v-5cb60a6f=""
              class="mx-2 mb-2"
            /><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.esm.browser'

export default {
  name: 'ProductList',
  components: {},
  data() {
    return {}
  },
  created() {
    this.$store.commit('SHOW_LOADING', true)
    this.getListRegion({ typeGet: 'all' }).then(res => {
      this.$store.commit('SHOW_LOADING', false)
    }).catch(e=>{
      this.$store.commit('SHOW_LOADING', false)
    })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListRegion']),
  },
  methods: {
    ...mapActions(['getListRegion','deleteRegion']),
    getDate(date) {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    },
    isActive(flag) {
      if (flag) {
        return '<button class="btn btn-success">Hoạt động</button>'
      }
      return '<button class="btn btn-danger">Ngừng hoạt động</button>'
    },
    handleDeleteRegion(id){
      if (!confirm("Are you sure!")) return false
      this.deleteRegion(id)
    }
  },
}
</script>

<style scoped>

</style>
